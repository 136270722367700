import api from "../../utils/api";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Import useParams for getting URL parameters
import Product from "../shared/product"; // Adjust the path if necessary
import Popup from "../shared/popup"; // Adjust the path if you have a Popup component
import "../../styles/shopProducts.css"; // Adjust the CSS import if necessary

const ShopCards = () => {
  const [products, setProducts] = useState([]); // State to store all products
  const [visibleCount, setVisibleCount] = useState(16); // Number of products to display initially
  const [categories, setCategories] = useState([]); // State to store categories
  const [selectedCategory, setSelectedCategory] = useState(""); // State for selected category
  const [totalProducts, setTotalProducts] = useState(0); // State to track total products
  const [isPopupVisible, setIsPopupVisible] = useState(false); // State for popup visibility
  const [popupProduct, setPopupProduct] = useState(null); // State for selected product details
  const { categoryId,  } = useParams(); // Get categoryId from URL parameters

  useEffect(() => {
    api
      .get("/category", { withCredentials: true })
      .then((response) => {
        setCategories(response.data.result); // Set categories
      })
      .catch((error) => {});

    if (categoryId) {
      setSelectedCategory(categoryId); // Set the selected category based on URL parameter
      fetchProductsByCategory(categoryId);
    } else {
      // Fetch all products if no categoryId is present
      fetchAllProducts();
    }
  }, [categoryId]); // Run effect again when categoryId changes

  const fetchAllProducts = () => {
    api
      .get("/product", { withCredentials: true })
      .then((response) => {
        setProducts(response.data.result);
        setTotalProducts(response.data.result.length); // Set total product count
      })
      .catch((error) => {});
  };

  const loadMoreProducts = () => {
    setVisibleCount((prevCount) => prevCount + 16); // Load more products
  };

  const fetchProductsByCategory = (categoryId) => {
    api
      .get(`/product/category/${categoryId}`, { withCredentials: true })
      .then((response) => {
        setProducts(response.data.products);
        setTotalProducts(response.data.products.length);
        setVisibleCount(16); // Reset visible count for new category
      })
      .catch((error) => {});
  };

  const handleCategoryChange = (event) => {
    const selectedCategoryId = event.target.value;
    setSelectedCategory(selectedCategoryId); // Update selected category

    // Reset visible count
    setVisibleCount(16);

    if (selectedCategoryId) {
      fetchProductsByCategory(selectedCategoryId);
    } else {
      // Fetch all products if no category is selected
      fetchAllProducts();
    }
  };

  const closePopup = () => {
    setIsPopupVisible(false); // Hide the popup
    setPopupProduct(null); // Clear the selected product
  };

  return (
    <div className="shop-container">
      <div className="shop-products-container">
      <h2 className="shop-title">Our Products</h2>
      <div className="shop-filters">
        <div className="labelSpan">
          <span className="spanTitle">Filter By Category: </span>
          <select
            onChange={handleCategoryChange}
            className="category-dropdown"
            value={selectedCategory} // Set the dropdown value to the selected category
          >
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.categoryName}
              </option>
            ))}
          </select>
        </div>
        <p className="product-count-top">
          Showing {Math.min(visibleCount, products.length)} of {totalProducts} Products
        </p>
      </div>
      <div className="shop-grid">
        {products.slice(0, visibleCount).map((product) => (
          <Product
            product={product}
            key={product.id}
            path={product.images.length > 0 ? product.images : ""}
            title={product.productName}
            description={product.description}
            name={product.productName} // Optional: Open popup on product click
          />
        ))}
      </div>
      {visibleCount < products.length && (
        <button onClick={loadMoreProducts} className="load-more-button">
          Load More
        </button>
      )}
      <p className="product-count">
        Showing {Math.min(visibleCount, products.length)} of {totalProducts} Products
      </p>

      {/* Show the Popup if it is visible */}
      {isPopupVisible && popupProduct && (
        <Popup
          isVisible={isPopupVisible}
          onClose={closePopup}
          product={{
            productId: popupProduct.id,
            imagePath: popupProduct.images.length > 0 ? popupProduct.images : "",
            title: popupProduct.productName,
            category: popupProduct.category.categoryName,
            description: popupProduct.description,
          }}
        />
      )}

    </div>
    </div>
  );
};

export default ShopCards;
