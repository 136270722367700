import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../../styles/slider.css";
import api from "../../utils/api";
import { Helmet } from "react-helmet-async";

const NewCarousel = () => {
  const [slides, setSlides] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startTouch, setStartTouch] = useState(0); // Track initial touch position
  const [isSwiping, setIsSwiping] = useState(false); // Track swipe state
  const slideDuration = 7000;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await api.get("/event/");
        const activeEvents = response.data.result;

        let slidesData = [];

        if (activeEvents.length > 0) {
          slidesData = activeEvents.map((event) => ({
            id: event.id,
            title: event.eventName,
            subtitle: event.eventDescription || "",
            description: event.slogan || "",
            image: event.images.length > 0
              ? `${process.env.REACT_APP_IMAGE_URL}${event.images[0].imageUrl}`
              : "assets/imageNotFound.png",
            buttonText: "View More About The Event",
          }));

          slidesData.push({
            id: -1,
            subtitle: "NEW COLLECTION",
            description: "Discover our finest selection",
            image: "assets/slide1.webp",
            buttonText: "View Our Products",
          });
        } else {
          slidesData = [
            {
              id: -1,
              subtitle: "NEW COLLECTION",
              description: "Discover our finest selection",
              image: "assets/slide1.webp",
              buttonText: "View Our Products",
            },
          ];
        }

        setSlides(slidesData);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    fetchItems();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, slideDuration);

    return () => clearInterval(interval);
  }, [slides.length]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
    );
  };


  return (
    <div
      className="carousel-container"
    >
      <Helmet>
        <meta
          name="description"
          content="Explore luxurious jewelry collections at Mounirto. Discover rings, necklaces, bracelets, and other fine jewelry items."
        />
        <meta
          name="keywords"
          content="luxury jewelry, fine jewelry, rings, necklaces, bracelets, Mounirto, high-end accessories, elegant jewelry, jewelry collection"
        />
      </Helmet>
      <div className="carousel-controls">
        <div className="prev-arrow" onClick={prevSlide}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
      </div>
      <div className="carousel-slide">
        <div
          className="slides"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {slides.length > 0 ? (
            slides.map((slide, index) => (
              <div key={index} className="slide">
                <div className="carousel-content">
                  <p className="small-text">{slide.subtitle}</p>
                  {slide.title&&(
                  <h1 className="big-text">{slide.title}</h1>
)}
                  <p className="medium-text">{slide.description}</p>

                  <button
                    className="cta-button"
                    onClick={() =>{
                      window.scrollTo(0, 0);
                      navigate(
                        slide.id === -1 ? "/shop" : `/shop/event/${slide.id}`
                      )
                    }
                    }
                  >
                    {slide.buttonText}
                    <span className="arrow"> &#8594;</span>
                  </button>
                </div>
                <img
                  src={slide.image}
                  alt={`Slide ${slide.id}`}
                  className="carousel-image"
                />
              </div>
            ))
          ) : (
            <p>Loading slides...</p>
          )}
        </div>
      </div>
      <div className="carousel-controls">
        <div className="next-arrow" onClick={nextSlide}>
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </div>
    </div>
  );
};

export default NewCarousel;
