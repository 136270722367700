import React, { useState, useEffect } from 'react';
import { FaChevronDown, FaChevronUp, FaSignOutAlt, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import '../../styles/AdminDashboard.css'; // Import your CSS file
import AddProductForm from './addProduct'; // Import the AddProductForm component
import ModifyProducts from './modifyProduct'; // Import the ModifyProducts component
import CustomerRequests from './customerRequests';
import RegisterAdmin from './adminRegister'; // Adjust this import according to your actual component
import AddEvent from './addEvent'; // Import the AddEvent component
import ModifyEvent from './modifyEvent'; // Import the ModifyEvent component
import PasswordChange from './passwordChange'
import AddCategory from './addCategory'
import ModifyCategory from './modifyCategory'
import DeleteCategory from './deleteCategory'
import Insights from './insights'
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import api from '../../utils/api';

const AdminDashboard = () => {
    const [isNavbarOpen, setIsNavbarOpen] = useState(true);
    const [isProductsOpen, setIsProductsOpen] = useState(false);
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    const [isEventsOpen, setIsEventsOpen] = useState(false); // State for the events dropdown
    const [currentComponent, setCurrentComponent] = useState(<Insights/>); // State to track the current component
    const navigate = useNavigate(); // Get the history object

    // Check for userId in localStorage
    useEffect(() => {
        const userId = localStorage.getItem('userId');
       
        if (!userId) {
            // If no userId, navigate back to admin login
            navigate('/admin');
            window.scrollTo(0, 0);
        }
    }, [navigate]); // Run this check only when the component mounts

    const toggleNavbar = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };

    const toggleProductsDropdown = () => {
        setIsProductsOpen(!isProductsOpen);
        setIsEventsOpen(false);
    };

    const toggleEventsDropdown = () => {
        setIsProductsOpen(false);
        setIsEventsOpen(!isEventsOpen); // Toggle the events dropdown
    };

    const toggleCategoriesDropdown = () => {
        setIsCategoryOpen(false);
        setIsCategoryOpen(!isCategoryOpen); // Toggle the events dropdown
    };

    const handleLogout = async () => {
        const userId = localStorage.getItem("userId"); // Get the userId from localStorage
        try {
            await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
          } catch (error) {
            console.error("User authentication failed:", error);
            localStorage.removeItem("userId"); // Reset userId in localStorage
            window.location.href = "/admin"; // Redirect to admin if authentication fails
          }
        await api.post(`/user/logout/${userId}`, { withCredentials: true }).then((response) => {
                localStorage.removeItem("userId"); // Clear userId from localStorage
                navigate('/admin'); // Use navigate to redirect
                window.scrollTo(0, 0);
            })
            .catch((error) => {
                console.error("Error during logout:", error);
            });
    };

    const handleAddProductClick = () => {
        setIsNavbarOpen(false); // Collapse navbar
        setCurrentComponent(<AddProductForm />); // Set the current component to AddProductForm
    };

    const handleModifyProductClick = () => {
        setIsNavbarOpen(false); // Collapse navbar
        setCurrentComponent(<ModifyProducts />); // Set the current component to ModifyProducts
    };


    const handleAddAdminClick = () => {
        setIsNavbarOpen(false); // Collapse navbar
        setCurrentComponent(<RegisterAdmin />); // Set the current component to RegisterAdmin
    };

    const handleCustomerReq = () => {
        setIsNavbarOpen(false); // Collapse navbar
        setCurrentComponent(<CustomerRequests />); // Set the current component to CustomerRequests
    };

    const handleAddEventClick = () => {
        setIsNavbarOpen(false); // Collapse navbar
        setCurrentComponent(<AddEvent />); // Set the current component to AddEvent
    };

    const handleModifyEventClick = () => {
        setIsNavbarOpen(false); // Collapse navbar
        setCurrentComponent(<ModifyEvent />); // Set the current component to ModifyEvent
    };

    const handleChangePassword = () => {
        setIsNavbarOpen(false); // Collapse navbar
        setCurrentComponent(<PasswordChange />); // Set the current component to ModifyEvent
    };
    const handleProductInsights = () => {
        setIsNavbarOpen(false); // Collapse navbar
        setCurrentComponent(<Insights />); // Set the current component to ModifyEvent
    };

    const handleAddCategory = () => {
        setIsNavbarOpen(false); // Collapse navbar
        setCurrentComponent(<AddCategory />); // Set the current component to ModifyEvent
    };
    const handleModifyCategory = () => {
        setIsNavbarOpen(false); // Collapse navbar
        setCurrentComponent(<ModifyCategory />); // Set the current component to ModifyEvent
    };
    const handleDeleteCategory = () => {
        setIsNavbarOpen(false); // Collapse navbar
        setCurrentComponent(<DeleteCategory />); // Set the current component to ModifyEvent
    };

    return (
        <div className="dashboard">
            <nav className={`admin-navbar ${isNavbarOpen ? 'open' : 'collapsed'}`}>
                <div className="admin-navbar-header">
                    {isNavbarOpen ? (
                        <>
                            Hello, Admin!
                            <div className="arrow-toggle" onClick={toggleNavbar}>
                                <FaArrowLeft />
                            </div>
                        </>
                    ) : (
                        <div className="arrow-toggle" onClick={toggleNavbar}>
                            <FaArrowRight />
                        </div>
                    )}
                </div>
                {isNavbarOpen && (
                    <ul>
                        <li onClick={handleProductInsights}>Product Insights</li>
                        <li onClick={toggleProductsDropdown}>
                            Products {isProductsOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </li>
                        {isProductsOpen && (
                            <ul className="dropdown" id="dropdown">
                                <li onClick={handleAddProductClick}>Add Product</li>
                                <li onClick={handleModifyProductClick}>Modify Product</li>
                            </ul>
                        )}
                        <li onClick={toggleCategoriesDropdown}>
                            Categories {isCategoryOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </li>
                        {isCategoryOpen && (
                            <ul className="dropdown" id="dropdown">
                                <li onClick={handleAddCategory}>Add Categories</li>
                                <li onClick={handleModifyCategory}>Modify Categories</li>
                                <li onClick={handleDeleteCategory}>Delete Categories</li>
                            </ul>
                        )}
                        <li onClick={toggleEventsDropdown}>
                            Events {isEventsOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </li>
                        {isEventsOpen && (
                            <ul className="dropdown" id="dropdown">
                                <li onClick={handleAddEventClick}>Add Event</li>
                                <li onClick={handleModifyEventClick}>Modify Event</li>
                            </ul>
                        )}
                        <li onClick={handleAddAdminClick}>
                            Add Admins 
                        </li>
                        <li onClick={handleCustomerReq}>Customer Requests</li>
                        
                        <li onClick={handleChangePassword}>Change Password</li>
                    </ul>
                )}
                <div className="logout-container">
                    {isNavbarOpen ? (
                       
                        <li className="logout" id="logout" onClick={handleLogout}>
                            <FaSignOutAlt /> Logout
                        </li>
                        
                    ) : (
                        <li className="logout-collapsed" id="logout-collapsed" onClick={handleLogout}>
                            <FaSignOutAlt />
                        </li>
                    )}
                </div>
            </nav>

            <div className="content">
                {currentComponent} {/* Render the current component here */}
            </div>
        </div>
    );
};

export default AdminDashboard;
