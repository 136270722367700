import React, { useState } from "react";
import "../../styles/popup.css"; // Import CSS for popup styling
import RequestPriceForm from "./requestPrice"; // Import the new form component

const Popup = ({ isVisible, onClose, product }) => {
  const [formVisible, setFormVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // State for the current image index
  const [touchStartX, setTouchStartX] = useState(0); // Track the X position of touch start
  const [touchEndX, setTouchEndX] = useState(0); // Track the X position of touch end

  const { productId, imagePath, title, category, description } = product;

  const handleRequestPriceClick = () => {
    setFormVisible(true);
  };

  const handleFormSubmit = (formData) => {
    setFormVisible(false);
    onClose();
  };

  // Function to handle next image
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imagePath.length);
  };

  // Function to handle previous image
  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + imagePath.length) % imagePath.length
    );
  };

  // Handle touch start
  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  // Handle touch move
  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
  };

  // Handle touch end
  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > 50) {
      nextImage(); // Swipe left
    }
    if (touchEndX - touchStartX > 50) {
      prevImage(); // Swipe right
    }
    setTouchStartX(0);
    setTouchEndX(0);
  };

  if (!isVisible) return null; // Don't render anything if not visible

  return (
    <>
      {/* Popup Component */}
      <div className="overlay">
        <div className="popup">
          <span className="close" onClick={onClose}>
            &times;
          </span>

          {/* Image Slider */}
          <div
            className="image-slider"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <button className="slider-button prev" onClick={prevImage}>
              ❮
            </button>
            <img
              src={`${process.env.REACT_APP_IMAGE_URL}${imagePath[currentImageIndex].imageUrl}`} // Image URL with environment variable
              alt={title}
              className="popup-image"
            />
            <button className="slider-button next" onClick={nextImage}>
              ❯
            </button>
          </div>

          <div className="details-container">
            <h2 className="popup-title">{title}</h2>
            <h4 className="popup-category">Category: {category}</h4>
            <hr className="divider" />
            <p className="popup-description">{description}</p>
            <button
              className="request-price-button"
              onClick={handleRequestPriceClick}
            >
              Request Price
            </button>
          </div>
        </div>

        {/* Form Popup Overlay */}
        {formVisible && (
          <div className="form-overlay">
            <RequestPriceForm
              productId={productId}
              onClose={() => setFormVisible(false)}
              onSubmit={handleFormSubmit}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Popup;
