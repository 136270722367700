import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../../utils/api";
import "../../styles/navbar.css"; // CSS for styling the navbar
// import logo from "/assets/logo.svg"; // Logo file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import Popup from "./popup"; // Import your Popup component

library.add(fas); // Add all solid icons to the library

function Navbar() {
  const [isOpen, setIsOpen] = useState(false); // State to control the dropdown menu visibility
  const [promotions, setPromotions] = useState([]); // Store all promotions
  const [currentPromotionIndex, setCurrentPromotionIndex] = useState(0); // Current promotion index
  const [isVisible, setIsVisible] = useState(true); // Manage component visibility
  const [searchActive, setSearchActive] = useState(false); // Manage search bar visibility
  const [searchQuery, setSearchQuery] = useState(""); // Manage search input
  const [searchResults, setSearchResults] = useState([]); // Store search results
  const [popupProduct, setPopupProduct] = useState(null); // State for popup product
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Manage popup visibility
  const [categories, setCategories] = useState([]); // Store categories for dropdown
  const [dropdownOpen, setDropdownOpen] = useState(false); // Control the dropdown menu visibility
  const [categoriesListOpen, setCategoriesListOpen] = useState(false); // Control the dropdown menu visibility
  const [loading, setLoading] = useState(false); //
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Default to true if screen width is <= 768px

  // Function to update `isMobile` based on screen size
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Set true if screen width is <= 768px
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize); // Listen for resize events

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // Function to toggle the dropdown menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const fetchPromotions = async () => {
    try {
      const response = await api.get("/event/");
      const result = await response.data.result;
      setPromotions(result);
      setLoading(false); // Set loading to false once data is fetched
    } catch (error) {
      console.error("Error fetching promotions:", error);
      setLoading(false); // Ensure loading is false even if there's an error
    }
  };
  const fetchCategories = async () => {
    try {
      const response = await api.get("/category"); // Adjust API endpoint as needed
      
      const sortedCategories = response.data.result
        ? response.data.result
            .map((category) => ({
              ...category,
              categoryName: category.categoryName.charAt(0).toUpperCase() + category.categoryName.slice(1), // Capitalize the first letter
            }))
            .sort((a, b) => a.categoryName.localeCompare(b.categoryName)) // Sort alphabetically
        : [];
        
      setCategories(sortedCategories); // Store sorted categories with capitalized names
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  
  

  useEffect(() => {
    fetchPromotions();
    fetchCategories();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPromotionIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % promotions.length; // Update based on the length of promotions
        return nextIndex; // Return the new index
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [promotions]);

  const closePopup = () => {
    setIsPopupVisible(false); // Close the popup
  };

  const handleSearchClick = () => {
    setSearchActive(true); // Activate the search bar
    setIsOpen(false); // Open the
  };

  const handleCloseSearch = () => {
    setSearchActive(false); // Deactivate the search bar
    setSearchQuery(""); // Clear search input
    setSearchResults([]); // Clear search results
  };

  const handleSearchChange = async (e) => {
    setSearchQuery(e.target.value); // Update search query
    if (e.target.value) {
      try {
        const response = await api.get(
          `/product/search?productSearch=${e.target.value}`
        );
        setSearchResults(response.data.result); // Store search results
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    } else {
      setSearchResults([]); // Clear search results if the input is empty
    }
  };

  const handleSearchResultClick = async (productId) => {
    try {
      const response = await api.get(`/product/${productId}`); // Fetch product details based on productId
      setPopupProduct(response.data.result); // Set the product for the popup
      setIsPopupVisible(true); // Show the popup
      setSearchActive(false); // Close the search bar
      setSearchResults([]); // Clear search results
      setSearchQuery(""); // Clear search input
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  const handleCategoryClick = (categoryId) => {
    // Redirect to the category page or shop all products
    navigate(`/shop/${categoryId}`);
    window.scrollTo(0, 0);
    setDropdownOpen(false); // Close dropdown after selection
  };

  const handleShopAllClick = () => {
    // Redirect to all products page
    navigate("/shop");
    window.scrollTo(0, 0);
    setDropdownOpen(false); // Close dropdown after selection
  };

  return (
    <div className="navContainer">
      {loading ? (
        <div>Loading promotions...</div>
      ) : (
        isVisible &&
        promotions.length > 0 &&
        promotions[currentPromotionIndex] && (
          <div
            style={{
              position: "sticky",
              top: 0,
              overflow: "hidden",
              whiteSpace: "nowrap",
              cursor: "pointer",
              backgroundColor: "#c9a236",
              padding: "15px",
              textAlign: "center",
              display: "block",
            }}
            onClick={() =>{
              navigate(`/shop/event/${promotions[currentPromotionIndex].id}`);
              window.scrollTo(0, 0);
            }
            } // Navigate to event page on click
          >
            <p
              style={{
                fontSize: "14px",
                display: "inline-block",
                transition: "transform 0.5s ease",
                color: "white",
                textTransform: "uppercase",
                margin: "0",
                paddingLeft: "10px",
              }}>
              {promotions[currentPromotionIndex].slogan || ""}
            </p>
            {/* <span
              onClick={() => setIsVisible(false)}
              style={{
                cursor: "pointer",
                transition: "transform 0.3s ease",
                fontSize: "20px",
                color: "white",
                display: "inline",
                position: "fixed",
                right: "40px",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "rotate(90deg)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "rotate(0deg)")
              }>
              X
            </span> */}
          </div>
        )
      )}

      <nav className="navbar">
              {/* Hamburger Menu Icon */}
              <div className="hamburger">
          <FontAwesomeIcon icon="fa-solid fa-bars"  onClick={toggleMenu} />
        </div>
        <div className="navbar-logo">
        <a href="/">
            <img src="/assets/logo.svg" alt="Logo" />
          </a>
        </div>

        {/* Center Section: Navigation Links */}
        <ul className={`navbar-center-links ${isOpen ? "active" : ""}`}>
          <li>
            <a href="/">Home</a>
          </li>
          <li
            onMouseEnter={() => setDropdownOpen(true)} // Open dropdown on hover
            onMouseLeave={() => setDropdownOpen(false)} // Close dropdown when mouse leaves
          >
            <a href="#">Our Products</a>
            {dropdownOpen && (
              <div className="dropdown-menu">
                <span
                  onClick={handleShopAllClick}
                  className="dropdown-item-main">
                  <strong>Shop All Products</strong>
                </span>
                {categories.length > 0 && (
                  <span className="dropdown-label">
                    <strong>Shop By Category:</strong>
                  </span>
                )}
                {categories.map((category) => (
                  <span
                    key={category.id}
                    onClick={() => handleCategoryClick(category.id)}
                    className="dropdown-item" // Optional: add a class for additional styling
                  >
                    {category.categoryName}
                  </span>
                ))}
              </div>
            )}
          </li>
          <li>
            <a href="/about-us">About Us</a>
          </li>
          <li>
            <a href="/contact-us">Contact Us</a>
          </li>
          {isOpen && (
            <li onClick={handleSearchClick} style={{ cursor: "pointer" }}>
              <a>Search Products</a>
            </li>
          )}
        </ul>
        {/* Right Section: Icons */}
        <div className="navbar-icons">
          <FontAwesomeIcon
            icon="fa-solid fa-magnifying-glass"
            className="icon"
            onClick={handleSearchClick}
            style={{ cursor: "pointer" }} // Adding pointer cursor to indicate clickability
          />
        </div>

        <div className="hamburger">
        <FontAwesomeIcon
            icon="fa-solid fa-magnifying-glass"
            className="icon"
            onClick={handleSearchClick}
            style={{ cursor: "pointer" }} // Adding pointer cursor to indicate clickability
          />
        </div>

      </nav>
      {/* Search Component */}
      {searchActive && (
        <div className={`search-sidebar ${searchActive ? "active" : ""}`}>
          <div className="search-header">
            <span onClick={handleCloseSearch} className="close-btn">
              X
            </span>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search Mounirto"
              id="searchProductsMenu"
            />
          </div>

          {searchResults.length > 0 && (
            <div className="search-results">
              <h3>Results:</h3>
              <div className="search-grid">
                {searchResults.map((result) => (
                  <div
                    key={result.id}
                    className="search-item"
                    onClick={() => handleSearchResultClick(result.id)}
                    style={{ cursor: "pointer" }}>
                    <div className="search-item-image">
                    <img
  src={`${process.env.REACT_APP_IMAGE_URL}${result.images[0]?.imageUrl}`} // Image URL with environment variable
  alt={result.productName}
/>

                    </div>
                    <div className="search-item-text">
                      <p className="product-name">{result.productName}</p>
                      <p className="product-description">
                        {result.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      {/* Search Component */}
      {isOpen && isMobile && (
        <div className={`search-sidebar ${searchActive ? "active" : ""}`}>
          <div className="search-header">
            <span onClick={toggleMenu} className="close-btn">
              X
            </span>
          </div>
          <ul className={`navbar-mobile-links ${isOpen ? "active" : ""}`}>
            <li>
              <a href="/">Home</a>
            </li>
            <li onClick={() => setDropdownOpen(!dropdownOpen)}>
              <a href="#" className="mobile-dropdown-link">
                Our Products
                {dropdownOpen ? (
                  <FiChevronUp className="dropdown-arrow" />
                ) : (
                  <FiChevronDown className="dropdown-arrow" />
                )}
              </a>
              {dropdownOpen && (
                <ul className="mobile-dropdown-menu">
                  <li
                    onClick={handleShopAllClick}
                    className="mobile-dropdown-item-main">
                    <strong>Shop All Products</strong>
                  </li>
                  {categories.length > 0 && (
                    <li
                      className="mobile-dropdown-item-main"
                      onClick={(e) => {
                        e.stopPropagation();
                        setCategoriesListOpen(!categoriesListOpen);
                      }}>
                      <span className="mobile-dropdown-link">
                        <strong> Shop By Category</strong>
                        {categoriesListOpen ? (
                          <FiChevronUp className="dropdown-arrow" />
                        ) : (
                          <FiChevronDown className="dropdown-arrow" />
                        )}
                      </span>
                    </li>
                  )}
                  {categoriesListOpen &&
                   categories.map((category) => (
                    <li
                      key={category.id}
                      onClick={() => handleCategoryClick(category.id)}
                      className="mobile-dropdown-item">
                      {category.categoryName}
                    </li>
                  ))
                  }
                </ul>
              )}
            </li>
            <li>
              <a href="/about-us">About Us</a>
            </li>
            <li>
              <a href="/contact-us">Contact Us</a>
            </li>
          </ul>
          <div className="search-header">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              onClick={() => {
                setDropdownOpen(false); // This will close the dropdown when the input is clicked
              }}
              placeholder="Search Mounirto"
              id="searchProductsMenu"
            />
          </div>

          {searchResults.length > 0 && (
            <div className="search-results">
              <h3>Results:</h3>
              <div className="search-grid">
                {searchResults.map((result) => (
                  <div
                    key={result.id}
                    className="search-item"
                    onClick={() => handleSearchResultClick(result.id)}
                    style={{ cursor: "pointer" }}>
                    <div className="search-item-image">
                      <img
  src={`${process.env.REACT_APP_IMAGE_URL}${result.images[0]?.imageUrl}`} // Image URL with environment variable
  alt={result.productName}
/>

                    </div>
                    <div className="search-item-text">
                      <p className="product-name">{result.productName}</p>
                      <p className="product-description">
                        {result.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {/* Popup Modal */}
      {isPopupVisible && popupProduct && (
        <Popup
          isVisible={isPopupVisible}
          product={{
            productId: popupProduct.id,
            imagePath: popupProduct.images,
            title: popupProduct.productName,
            category: popupProduct.category.categoryName,
            description: popupProduct.description,
          }}
          onClose={closePopup}
        />
      )}
    </div>
  );
}

export default Navbar;
