import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp, // Import WhatsApp icon
} from "@fortawesome/free-brands-svg-icons";
import { faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons"; // Remove the faPhone import
import "../../styles/ContactUsForm.css"; // Assuming you save the styles in a separate file
import Navbar from "./navbar"; // Adjust the path as necessary
import Footer from "./footer"; // Adjust the path as necessary

const ContactForm = () => {
  return (
    <div>
      <Navbar />
      <div className="contact-container">
        {/* Left Side with Google Map and Contact Info */}
        <div className="contact-side">
          <div className="contact-google-map">
            <h3>Visit Us in Our Store</h3>
            {/* Embed Google Map here */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3775.100007887815!2d31.370621507186815!3d30.10131772103195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458166b4beae6b9%3A0x809f91f73e4f1633!2sMounirto!5e0!3m2!1sen!2seg!4v1729289850253!5m2!1sen!2seg"
              width="100%"
              height="70%"
              style={{ border: 0, minHeight: "70%", height: "85%" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Map Location"
            />
          </div>
        </div>

        {/* Right Side with Contact Info and Form Fields */}
        <div className="contact-right-side">
          <div className="contact-info">
            <h3>Contact Us:</h3>
            <p>
              <strong>
                Available daily from 10 AM to 11 PM, except Sundays.
              </strong>
            </p>

            <div className="branchesContainer">
              <div className="cairo-branch">
                <strong>Cairo: </strong>
                <p style={{ marginTop: "0", marginBottom: "0" }}>
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="contact-icon"
                  />
                  <a
                    href="https://maps.app.goo.gl/RKRjLw7gyerxQ7Tu8"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}>
                    11 Gad El-haq, Sheraton Al Matar, El Nozha
                  </a>
                </p>
                Call or message us on WhatsApp:
                <br />
                <div className="field">
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    className="contact-icon-wp"
                  />
                  <a
                    href="https://wa.me/201220647111"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}>
                    +201220647111
                  </a>
                </div>
                <p>
                  Call us on our landline:
                  <div className="field">
                    <FontAwesomeIcon icon={faPhone} className="contact-icon" />
                    <a
                      href="tel:+20222696244"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "flex",
                        alignItems: "center", // Ensures vertical alignment
                      }}>
                      +20222696244
                    </a>
                  </div>
                </p>
              </div>
              <div className="gouna-branch">
                <strong>El Gouna: </strong>
                <p style={{ margin: "0" }}>
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="contact-icon"
                  />
                  <a
                    href="https://maps.app.goo.gl/XtLKEFdLfZkyTy4j9"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}>
                    Steingenberger Golf Hotel
                  </a>
                </p>
                <p style={{ margin: "0" }}>
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="contact-icon"
                  />
                  <a
                    href="https://maps.app.goo.gl/9NkHeL3Bu3XR1Atq5"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}>
                    Marina El Gouna, Next To HSBC Bank
                  </a>
                </p>
                <p style={{ margin: "0" }}>
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="contact-icon"
                  />
                  <a
                    href="https://maps.app.goo.gl/RKRjLw7gyerxQ7Tu8"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}>
                    Kafr El Gouna, El khayameya, Downtown
                  </a>
                </p>
                Call or message us on WhatsApp:
                <br />
                <div className="field">
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    className="contact-icon-wp"
                  />
                  <a
                    href="https://wa.me/201204919702"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}>
                    +201204919702
                  </a>
                </div>
                <p>
                  Call us on our landline:
                  <div className="field">
                    <FontAwesomeIcon icon={faPhone} className="contact-icon" />
                    <a
                      href="tel:+20653549702"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "flex",
                        alignItems: "center", // Ensures vertical alignment
                      }}>
                      +20 65 354 9702/3 ext 32112
                    </a>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactForm;
