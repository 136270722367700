import React, { useState, useEffect } from 'react';
import { ImageRender } from './ImageRender';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import api from '../../utils/api';
import { Helmet } from 'react-helmet-async';

export default function Carousel({ eventId }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [slides, setSlides] = useState([]); // Array to hold slides from the API
    const [eventDetails, setEventDetails] = useState(null); // Store event details for meta tags
    const [startTouch, setStartTouch] = useState(0); // Start touch position for swipe detection
    const [intervalId, setIntervalId] = useState(null); // Timer interval state

    // Fetch event details based on eventId
    useEffect(() => {
        const fetchEventDetails = async () => {
            try {
                const response = await api.get(`/event/`);
                const eventData = response.data.result;
                const event = eventData.find(event => event.id == eventId);

                if (event && event.images) {
                    const mappedSlides = event.images.map((image, index) => ({
                        id: index,
                        url: `${process.env.REACT_APP_IMAGE_URL}${image.imageUrl}`,
                        p: event.slogan,
                        h1: event.eventName,
                        p2: event.eventDescription,
                    }));
                    setSlides(mappedSlides);
                    setEventDetails(event);
                } else {
                    setSlides([]);
                }
            } catch (error) {
                console.error("Error fetching event details:", error);
            }
        };

        fetchEventDetails();
    }, [eventId]);

    // Automated sliding effect with interval
    useEffect(() => {
        const interval = setInterval(() => {
            goForward();
        }, 5000);

        setIntervalId(interval);
        return () => clearInterval(interval); // Cleanup interval on unmount or eventId change
    }, [slides, currentIndex]);

    const goBack = () => {
        const firstSlide = currentIndex === 0;
        const newIndex = firstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const goForward = () => {
        const lastSlide = currentIndex === slides.length - 1;
        const newIndex = lastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };


    const containerStyle = {
        color: 'white',
        position: 'relative',
        width: '100%',
        display: 'flex',
        overflowX: 'hidden', // Allow horizontal scroll
        overflowY: 'hidden', // Disable vertical scroll
        scrollSnapType: 'x mandatory', // Smooth horizontal scrolling
        WebkitOverflowScrolling: 'touch', // Smooth scrolling on iOS
    };

    const navigationStyle = {
        position: 'absolute',
        top: '50%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0',
        zIndex: 10,
    };

    const arrowStyle = {
        color: 'black',
        width: '40px',
        height: '40px',
        padding: '8px',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    };

    const arrowHoverStyle = {
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
    };

    const contentStyle = {
        position: 'absolute',
        width: '100%',
        top: '12%',
        left: '0',
        zIndex: 2,
        textAlign: 'center',
        padding: '20px',
    };

    const text1Style = {
        color: 'white',
        fontSize: '36px',
        fontFamily: 'Playfair Display, Roboto, sans-serif',
        fontStyle: 'italic',
        fontWeight: 400,
    };

    const text2Style = {
        fontSize: '3rem',
        fontWeight: 700,
        fontFamily: 'Playfair Display, Montserrat, sans-serif',
    };

    const text3Style = {
        fontSize: '22px',
        color: 'white',
        overflowY: 'auto',
        maxHeight: '38vh',
        padding: '10px 0',
        lineHeight: '1.2',
    };

    return (
        <>
            {eventDetails && (
                <Helmet>
                    <meta name="description" content={eventDetails.eventDescription || 'Explore amazing events and details with our carousel.'} />
                    <meta name="keywords" content={eventDetails.eventName + ", events, images, showcase"} />
                    <meta property="og:title" content={eventDetails.eventName} />
                    <meta property="og:description" content={eventDetails.eventDescription} />
                    <meta property="og:image" content={`${process.env.REACT_APP_IMAGE_URL}${eventDetails.images[0].imageUrl}`} />
                </Helmet>
            )}
            <div
                style={containerStyle}
            >
                {slides.map(slide => {
                    if (slide.id === currentIndex) {
                        return <ImageRender key={slide.id} slide={slide} />;
                    }
                    return null;
                })}
                {slides[0] && (
                    <div style={contentStyle}>
                        <h1 style={text1Style}>{slides[0].p}</h1>
                        <p style={text2Style}>{slides[0].h1}</p>
                        <p style={text3Style}>{slides[0].p2}</p>
                    </div>
                )}
                <div style={navigationStyle}>
                    <FaArrowLeft
                        onClick={goBack}
                        style={arrowStyle}
                        onMouseOver={e => (e.currentTarget.style.backgroundColor = arrowHoverStyle.backgroundColor)}
                        onMouseOut={e => (e.currentTarget.style.backgroundColor = '')}
                    />
                    <FaArrowRight
                        onClick={goForward}
                        style={arrowStyle}
                        onMouseOver={e => (e.currentTarget.style.backgroundColor = arrowHoverStyle.backgroundColor)}
                        onMouseOut={e => (e.currentTarget.style.backgroundColor = '')}
                    />
                </div>
            </div>
        </>
    );
}
