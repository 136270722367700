import api from "../../utils/api";
import React, { useEffect, useState } from "react";
import Product from "../shared/product";
import "../../styles/shopProducts.css";
import { useParams, useNavigate } from "react-router-dom";
import Carousel from "../homePage/eventSlider";
const ShopCards = () => {
  const { eventId } = useParams();
  const [products, setProducts] = useState([]);
  const [eventProducts, setEventProducts] = useState([]); // Store event products separately
  const [visibleCount, setVisibleCount] = useState(16);
  const [categories, setCategories] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    api.get("/category", { withCredentials: true })
      .then((response) => setCategories(response.data.result))
      .catch(() => alert("Failed to fetch categories. Please try again."));

    api.get(`/event/${eventId}`, { withCredentials: true })
      .then((response) => {
        const eventProductsData = response.data.result.products || [];
        const productRequests = eventProductsData.map((product) =>
          api.get(`/product/${product.id}`, { withCredentials: true })
        );

        Promise.all(productRequests)
          .then((productResponses) => {
            const detailedProducts = productResponses.map((res) => res.data.result);
            setEventProducts(detailedProducts); // Set event-specific products
            setProducts(detailedProducts); // Initially show all event products
            setTotalProducts(detailedProducts.length);
          })
          .catch(() => alert("Failed to fetch product details. Please try again."));
      })
      .catch(() => alert("Failed to fetch products for the event. Please try again."));
  }, [eventId]);

  const loadMoreProducts = () => {
    setVisibleCount((prevCount) => prevCount + 16);
  };

  const handleCategoryChange = (event) => {
    const selectedCategoryId = event.target.value;
    setVisibleCount(16);

    if (selectedCategoryId) {
       
      // Filter event products by selected category
      const filteredProducts = eventProducts.filter(
        (product) => product.categoryId == selectedCategoryId
      );
      setProducts(filteredProducts);
      setTotalProducts(filteredProducts.length);
    } else {
      // Reset to show all event products if no category is selected
      setProducts(eventProducts);
      setTotalProducts(eventProducts.length);
    }
  };

  const clearEventFilter = () => {
    navigate("/shop");
    window.scrollTo(0, 0);
  };

  return (
    <div className="shop-container">
      <Carousel eventId={eventId} />
      <div className="shop-products-container">
      <h2 className="shop-title">Event's Products</h2>

      <div className="event-info" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px", border: "1px solid #C9A236", borderRadius: "5px", marginBottom: "20px", textDecoration: "underline", color: "#C9A236" }}>
        <span style={{ fontWeight: "bold" }}>Products included in Event</span>
        <button
          onClick={clearEventFilter}
          className="clear-filter-button"
          style={{ backgroundColor: "#C9A236", color: "#fff", border: "none", borderRadius: "5px", padding: "5px 10px", cursor: "pointer", transition: "background-color 0.3s" }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#b68b26")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#C9A236")}
        >
          X
        </button>
      </div>

      <div className="shop-filters">
        <div className="labelSpan">
          <span className="spanTitle">Filter By Category: </span>
          <select onChange={handleCategoryChange} className="category-dropdown">
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.categoryName}
              </option>
            ))}
          </select>
        </div>
        <p className="product-count-top">
          Showing {Math.min(visibleCount, products.length)} of {totalProducts} Products
        </p>
      </div>

      <div className="shop-grid">
        {products.slice(0, visibleCount).map((product) => (
          <Product
            product={product}
            key={product.id}
            path={product.images.length > 0 ? product.images : ""}
            title={product.productName}
            description={product.description}
            name={product.productName}
          />
        ))}
      </div>

      {visibleCount < products.length && (
        <button onClick={loadMoreProducts} className="load-more-button">
          Load More
        </button>
      )}

      <p className="product-count">
        Showing {Math.min(visibleCount, products.length)} of {totalProducts} Products
      </p>
    </div>
    </div>
  );
};

export default ShopCards;
