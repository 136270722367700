import React, { useEffect, useState } from 'react';
import api from '../../utils/api';
import Select from 'react-select';
import '../../styles/ModifyEventForm.css';

const ModifyEventForm = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventName, setEventName] = useState('');
  const [eventSlogan, setEventSlogan] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [images, setImages] = useState([]); // State for newly uploaded images
  const [existingImages, setExistingImages] = useState([]); // State for existing event images
  const [products, setProducts] = useState([]);
  const [assignedProducts, setAssignedProducts] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal
  

  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const userId = localStorage.getItem('userId');

  useEffect(() => {
    fetchEvents();
    fetchProducts();
  },[]);

  const fetchEvents = async () => {
    try {
      try {
        await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
      } catch (error) {
        console.error("User authentication failed:", error);
        localStorage.removeItem("userId"); // Reset userId in localStorage
        window.location.href = "/admin"; // Redirect to admin if authentication fails
      }
      const response = await api.get(`/event/admin/${userId}`, { withCredentials: true });
      setEvents(response.data.result);
    } catch (error) {
      console.error('Error fetching events:', error);
      setErrorMessage(error.response.data.details);
    }
  };

  const fetchProducts = async () => {
    try {
      try {
        await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
      } catch (error) {
        console.error("User authentication failed:", error);
        localStorage.removeItem("userId"); // Reset userId in localStorage
        window.location.href = "/admin"; // Redirect to admin if authentication fails
      }
      const response = await api.get('/product', { withCredentials: true });
      const productOptions = response.data.result.map((product) => ({
        value: product.id,
        label: product.productName,
      }));
      setProducts(productOptions);
    } catch (error) {
      console.error('Error fetching products:', error);
      setErrorMessage(error.response.data.details);
    }
  };

  const handleSelectEvent = (event) => {
    setSuccessMessage('');
    setErrorMessage('');
    setSelectedEvent(event);
    setEventName(event.eventName);
    setEventSlogan(event.slogan || '');
    setEventDescription(event.eventDescription);
    setStartDate(event.startDate);
    setEndDate(event.endDate);
    setImages([]); // Reset newly uploaded images when selecting a new event

    // Fetch existing images from the event
    setExistingImages(event.images || []); // Assuming `event.images` contains the existing images

    setAssignedProducts(
      event.products.map((product) => ({
        value: product.id,
        label: product.productName,
      }))
    );
    
    setAvailableProducts(
      products.filter(
        (product) => !event.products.some((assigned) => assigned.id == product.value)
      )
    );
    
  };

  const handleProductChange = (selectedOptions) => {
    setSelectedProducts(selectedOptions || []);
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(prevImages => [...prevImages, ...files]);
  };

  const handleRemoveImage = (index) => {
    setImages(prevImages => prevImages.filter((_, i) => i !== index));
  };
  
  const handleRemoveExistingImage = async (index) => {
    const image = existingImages[index]; // Get the specific image to delete
    if (!image) return; // Check if the image exists

    try {
      try {
        await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
      } catch (error) {
        console.error("User authentication failed:", error);
        localStorage.removeItem("userId"); // Reset userId in localStorage
        window.location.href = "/admin"; // Redirect to admin if authentication fails
      }
      await api.delete(`/event/delete-eventImage/${userId}/${selectedEvent.id}/${image.id}`, {
        withCredentials: true,
      });

      // Update state to remove the deleted image
      setExistingImages(prevImages => prevImages.filter((_, i) => i !== index));
    } catch (error) {
      console.error('Error deleting existing image:', error);
      setErrorMessage('Failed to delete existing image.');
    }
  };

  const handleUnassignProduct = async (productId, productLabel) => {
    try {
      // Authenticate user
      try {
        await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
      } catch (error) {
        console.error("User authentication failed:", error);
        localStorage.removeItem("userId"); // Reset userId in localStorage
        window.location.href = "/admin"; // Redirect to admin if authentication fails
      }
      
      // Unassign product
      await api.delete(`/event/unassign-products/${userId}/${selectedEvent.id}`, {
        withCredentials: true,
        data: { productsIds: [productId] },
      });
      
      // Filter out unassigned product from assignedProducts
      const updatedAssignedProducts = assignedProducts.filter(
        (product) => product.value !== productId
      );
      setAssignedProducts(updatedAssignedProducts);
      
      // Find the unas
        setAvailableProducts((prevAvailableProducts) => [
          ...prevAvailableProducts,
          { value: productId, label: productLabel },
        ]);
      
      
      // Set success message
      setSuccessMessage('Product unassigned successfully');
      setErrorMessage('');
    } catch (error) {
      console.error('Error unassigning product:', error);
      setErrorMessage('Failed to unassign product.');
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    if (selectedEvent) {
      setErrorMessage('');
      try {
        try {
          await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
        } catch (error) {
          console.error("User authentication failed:", error);
          localStorage.removeItem("userId"); // Reset userId in localStorage
          window.location.href = "/admin"; // Redirect to admin if authentication fails
        }
        await api.put(`/event/update-event/${userId}/${selectedEvent.id}`, {
          eventName,
          slogan: eventSlogan,
          eventDescription,
          startDate,
          endDate,
        }, { withCredentials: true });

        // Handle image upload
        if (images.length > 0) {
          const formData = new FormData();
          images.forEach((image) => formData.append('images', image));
          try {
            await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
          } catch (error) {
            console.error("User authentication failed:", error);
            localStorage.removeItem("userId"); // Reset userId in localStorage
            window.location.href = "/admin"; // Redirect to admin if authentication fails
          }
          await api.post(`/event/upload-eventImage/${userId}/${selectedEvent.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
          });
        }

        if (selectedProducts.length > 0) {
          try {
            await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
          } catch (error) {
            console.error("User authentication failed:", error);
            localStorage.removeItem("userId"); // Reset userId in localStorage
            window.location.href = "/admin"; // Redirect to admin if authentication fails
          }
          await api.post(`/event/assign-products/${userId}/${selectedEvent.id}`, {
            productsIds: selectedProducts.map((product) => product.value),
          }, { withCredentials: true });
        }

        setSuccessMessage('Event updated successfully!');
        fetchEvents();
        resetForm();
      } catch (error) {
        console.error('Error updating event:', error);
        setErrorMessage(error.response.data.details || 'Failed to update event.');
      } finally {
        setIsUpdating(false);
      }
    }
  };

  const handleDeleteEvent = () => {
    setShowDeleteModal(true); // Show the delete confirmation modal
  };

  const deleteEvent = async () => {
    setIsDeleting(true); 
    try {
      try {
        await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
      } catch (error) {
        console.error("User authentication failed:", error);
        localStorage.removeItem("userId"); // Reset userId in localStorage
        window.location.href = "/admin"; // Redirect to admin if authentication fails
      }
      await api.delete(`/event/delete-event/${userId}/${selectedEvent.id}`, { withCredentials: true });
      setSuccessMessage("Event deleted successfully.");
      fetchEvents();
      resetForm();
    } catch (error) {
      console.error('Error deleting event:', error);
      setErrorMessage('Failed to delete event.');
    } finally {
      setIsDeleting(false);
      setShowDeleteModal(false); // Hide the modal after deletion attempt
    }
  };

  const resetForm = () => {
    setSelectedEvent("");
    setEventName('');
    setEventSlogan('');
    setEventDescription('');
    setStartDate('');
    setEndDate('');
    setImages([]); // Reset images
    setExistingImages([]); // Reset existing images
    setAssignedProducts([]);
    setSelectedProducts([]);
  };

  return (
    <div className="modify-event-form-container">
      <div className="modify-event-form">
        <h2 className="modify-event-form-title">Modify Event</h2>
    

        {!selectedEvent && (
        <select
          onChange={(e) => handleSelectEvent(JSON.parse(e.target.value))}
          className="modify-event-select"
          defaultValue=""
          value={selectedEvent}
        >
          <option value="" disabled>Select an event to modify</option>
          {events.map((event) => (
            <option key={event.id} value={JSON.stringify(event)}>
              {event.eventName}
            </option>
          ))}
        </select>)}

        {selectedEvent && (
          <form onSubmit={handleSubmit} className="modify-event-formFields">
            <input
              type="text"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              placeholder="Event Name"
              required
              className="modify-event-input"
            />
            <input
              type="text"
              value={eventSlogan}
              onChange={(e) => setEventSlogan(e.target.value)}
              placeholder="Event Slogan"
              required
              className="modify-event-input"
            />
            <textarea
              value={eventDescription}
              onChange={(e) => setEventDescription(e.target.value)}
              placeholder="Event Description"
              required
              className="modify-event-textarea"
            />
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
              className="modify-event-input"
            />
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              required
              className="modify-event-input"
            />

            <div className="modify-event-product-selection">
              <h3>Assign Products:</h3>
              <Select
                isMulti
                options={availableProducts}
                value={selectedProducts}
                onChange={handleProductChange}
                className="modify-event-select"
              />
               </div>
               <div className="modify-event-product-selection">
              <h3>Assigned Products:</h3>
              {assignedProducts.map((product) => (
                <div key={product.value} className="assigned-product">
                  <span>{product.label}</span>
                  <button
                    type="button"
                    className="unassign-product-button"
                    onClick={() => handleUnassignProduct(product.value, product.label)}
                  >
                    Unassign
                  </button>
                </div>
              ))}
            </div>

            <div className="modify-event-product-selection">
            <h3>Upload Images:</h3>
              <input
                type="file"
                multiple
                onChange={handleImageChange}
                className="add-event-image-input"
              />
               </div>
               <div className="modify-event-product-selection">
                {existingImages.length>0 &&<h3>Existing Images:</h3>}
                <div className="image-previews">
           {existingImages.map((image, index) => (
            <div key={index} className="image-preview">
              <img
  src={`${process.env.REACT_APP_IMAGE_URL}${image.imageUrl}`}
  alt={`Preview ${index}`}
  className="image-preview-thumbnail"
/>

              {existingImages.length>1 && <button
                type="button"
                onClick={() => handleRemoveExistingImage(index)}
                className="add-event-remove-image-button"
              >
                X
              </button>}
            </div>
          ))}
          </div>
          {images.length>0 && <h3>Images To Be Uploaded:</h3>}
          <div className="image-previews">
              {images.map((image, index) => (
            <div key={index} className="image-preview">
              <img src={URL.createObjectURL(image)} alt={`Preview ${index}`} className="image-preview-thumbnail" />
              <button
                type="button"
                onClick={() => handleRemoveImage(index)}
                className="add-event-remove-image-button"
              >
                X
              </button>
            </div>
          ))}
          </div>
         
            </div>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}
            <button onClick={() => setSelectedEvent("")} className="modify-event-cancel-button">Cancel</button>

            <button type="submit" className="modify-event-submit-button" disabled={isUpdating}>
              {isUpdating ? 'Updating...' : 'Update Event'}
            </button>
              <button type="button" onClick={handleDeleteEvent} className="modify-event-delete-button">Delete Event</button>
           
            
          </form>
        )}

        {showDeleteModal && (
        <div className="admin-modal">
          <div className="admin-modal-content">
            <h3>Are you sure you want to delete this event?</h3>
            <p>**This action cannot be undone.</p>
            <div className="admin-modal-actions">
            <button
                  className="admin-confirm-delete-btn"
                  onClick={deleteEvent}
                  disabled={isDeleting}
                >
                   {isDeleting ? 'Deleting...' : 'Yes'}
                </button>
              <button
                className="admin-cancel-delete-btn"
                onClick={() => setShowDeleteModal(false)}>
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {successMessage && <div className="success-message">{successMessage}</div>}
      </div>
      
    </div>
  );
};

export default ModifyEventForm;
