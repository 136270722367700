import React, { useEffect, useState, useRef } from 'react';
import api from '../../utils/api';
import Product from '../shared/product'; // Adjust the path if necessary
import '../../styles/trendingProducts.css';

const CategoryCards = () => {
  const [categoriesWithProducts, setCategoriesWithProducts] = useState([]); // Hold categories with their products
  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const cardRef = useRef(null);
  const [maxCategories, setMaxCategories] = useState(5); // Default to 5 categories

  // Determine max categories based on screen width
  const updateMaxCategories = () => {
    const screenWidth = window.innerWidth;
    setMaxCategories(screenWidth <= 768 ? 3 : 5); // 3 for mobile, 5 for laptops
  };

  // Fetch categories and their products on component mount
  useEffect(() => {
    const fetchCategoriesAndProducts = async () => {
      setCategoriesLoading(true);
      try {
        const response = await api.get('/category');
        const categoryData = response.data.result || [];

        const categoriesWithProducts = [];
        for (const category of categoryData) {
          const productResponse = await api.get(`/product/trendingproducts/${category.id}`);
          if (productResponse.data.result.length > 0) {
            categoriesWithProducts.push({
              ...category,
              products: productResponse.data.result, // Add products to category
            });
          }
        }

        if (categoriesWithProducts.length > 0) {
          const shuffledCategories = categoriesWithProducts.sort(() => 0.5 - Math.random());
          setCategoriesWithProducts(shuffledCategories); // Store categories along with their products
          setCategoryId(shuffledCategories[0].id); // Default to the first category
        }
      } catch (error) {
        console.error('Error fetching categories and products:', error);
      } finally {
        setCategoriesLoading(false);
      }
    };

    fetchCategoriesAndProducts();
    updateMaxCategories(); // Set max categories initially

    // Update max categories on resize
    window.addEventListener('resize', updateMaxCategories);

    return () => {
      window.removeEventListener('resize', updateMaxCategories);
    };
  }, []);

  const selectedCategory = categoriesWithProducts.find(
    (category) => category.id === categoryId
  );

  useEffect(() => {
    const handleResize = () => {
      requestAnimationFrame(updateArrowStates);
    };

    if (selectedCategory?.products?.length > 0) {
      requestAnimationFrame(() => {
        updateArrowStates();
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [selectedCategory]);

  const scrollLeft = () => {
    if (cardRef.current) {
      const productCard = cardRef.current.querySelector('.trending-product-width');
      const cardWidth = productCard ? productCard.offsetWidth : 300;
      cardRef.current.scrollBy({ left: -cardWidth, behavior: 'smooth' });
      setTimeout(updateArrowStates, 300);
    }
  };

  const scrollRight = () => {
    if (cardRef.current) {
      const productCard = cardRef.current.querySelector('.trending-product-width');
      const cardWidth = productCard ? productCard.offsetWidth : 300;
      cardRef.current.scrollBy({ left: cardWidth, behavior: 'smooth' });
      setTimeout(updateArrowStates, 300);
    }
  };

  const updateArrowStates = () => {
    if (cardRef.current) {
      const scrollLeft = cardRef.current.scrollLeft;
      const scrollWidth = cardRef.current.scrollWidth;
      const clientWidth = cardRef.current.clientWidth;

      setIsLeftDisabled(scrollLeft === 0);
      if (clientWidth === scrollWidth) {
        setIsRightDisabled(false);
        return;
      }
      setIsRightDisabled(scrollLeft + clientWidth > scrollWidth - 5);
    }
  };

  return (
    <div className="trending-container">
      <h2 className="trending-title">Trending Products</h2>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {categoriesLoading ? (
          <div className="loading">Loading categories...</div>
        ) : (
          categoriesWithProducts.slice(0, maxCategories).map((category) => (
            <button
              key={category.id}
              className={`trending-button ${category.id === categoryId ? 'active' : ''}`}
              onClick={() => setCategoryId(category.id)}
            >
              {category.categoryName}
            </button>
          ))
        )}
      </div>
      <div className="product-slider">
        <button
          className="trending-arrow-button left-arrow"
          onClick={scrollLeft}
          disabled={isLeftDisabled}
        >
          &#8249;
        </button>
        <div className="product-cards" ref={cardRef} onScroll={updateArrowStates}>
          {productsLoading ? (
            <div className="loading">Loading products...</div>
          ) : selectedCategory?.products?.length > 0 ? (
            selectedCategory.products.map((product) => (
              <div className="trending-product-width" key={product.id}>
                <Product
                  product={product}
                  path={product.images}
                  title={product.productName}
                  description={product.description}
                  category={product.category.categoryName}
                  name={product.productName}
                />
              </div>
            ))
          ) : (
            <div>No products available</div>
          )}
        </div>
        <button
          className="trending-arrow-button right-arrow"
          onClick={scrollRight}
          disabled={isRightDisabled}
        >
          &#8250;
        </button>
      </div>
    </div>
  );
};

export default CategoryCards;
