import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faXTwitter,
  faWhatsapp, // Import WhatsApp icon
} from "@fortawesome/free-brands-svg-icons";
import { faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons"; // Remove the faPhone import
import "../../styles/footer.css"; // Make sure to import your CSS file

const Footer = () => {
  return (
    <div className="main-container">
      <div className="container">
        {/* First Column */}
        <div className="column column1">
          <img src="/assets/logo.svg" alt="Logo" />
          <p>
            Unleash the radiance of your inner beauty with our premium jewelry
            brand - a perfect blend of sophistication and style.
          </p>
        </div>

        {/* Fourth Column */}
        <div className="column column4 footer-contact">
          <h4>Contact Us</h4>
          <h5>Cairo</h5>
          <p style={{ marginTop: "0" }}>
            <FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" />
            <a
              href="https://maps.app.goo.gl/RKRjLw7gyerxQ7Tu8"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}>
              11 Gad El-haq, Sheraton Al Matar, El Nozha
            </a>
          </p>

          <p style={{ marginTop: "0" }}>
            <FontAwesomeIcon icon={faPhone} className="contact-icon" />
            <a
              href="tel:+20222696244"
              style={{ textDecoration: "none", color: "inherit" }}>
              +202 2269 6244
            </a>
          </p>
          <p style={{ marginTop: "0" }}>
            <FontAwesomeIcon icon={faWhatsapp} className="contact-icon-wp" />{" "}
            {/* Use WhatsApp icon */}
            <a
              href="https://wa.me/+201220647111"
              target="_blank"
              style={{ textDecoration: "none", color: "inherit" }}>
              +201220647111
            </a>
          </p>
        </div>
        <div className="column column4 footer-contact">
          <h4></h4>
          <h5>El Gouna</h5>
          <p style={{ marginTop: "0" }}>
            <FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" />
            <a
              href="https://maps.app.goo.gl/XtLKEFdLfZkyTy4j9"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}>
              Steingenberger Golf Hotel
            </a>
          </p>
          <p style={{ marginTop: "0" }}>
            <FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" />
            <a
              href="https://maps.app.goo.gl/9NkHeL3Bu3XR1Atq5"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}>
              Marina El Gouna, Next To HSBC Bank
            </a>
          </p>
          <p style={{ marginTop: "0" }}>
            <FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" />
            <a
              href="https://maps.app.goo.gl/RKRjLw7gyerxQ7Tu8"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}>
              Kafr El Gouna, El khayameya, Downtown
            </a>
          </p>

          <p style={{ marginTop: "0" }}>
            <FontAwesomeIcon icon={faPhone} className="contact-icon" />
            <a
              href="tel:+20653549702"
              style={{ textDecoration: "none", color: "inherit" }}>
              +20 65 354 9702/3 ext 32112
            </a>
          </p>

          <p style={{ marginTop: "0" }}>
            <FontAwesomeIcon icon={faWhatsapp} className="contact-icon-wp" />{" "}
            {/* Use WhatsApp icon */}
            <a
              href="https://wa.me/+201204919702"
              target="_blank"
              style={{ textDecoration: "none", color: "inherit" }}>
              +201204919702
            </a>
          </p>
          <div className="social-icons">
            <a
              href="https://www.facebook.com/MounirtoEgypt/"
              target="_blank"
              rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="https://www.instagram.com/mounirto_jewellery/?hl=en"
              target="_blank"
              rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://twitter.com/shiekhmounir"
              target="_blank"
              rel="noopener noreferrer">
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <p style={{ fontSize: "12px", textAlign: "center" }}>
          Copyrights © 2024 Mounirto. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
