import React from 'react';

export const ImageRender = ({ slide }) => {
    const styleImg = {
        backgroundImage: `url(${slide.url})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover', // Ensures the image covers the container
        width: '100vw',
        margin: 'auto',
        opacity: '1',
        height: '100vh',
        zIndex: 1,
        position: 'relative',
    };
    
    const overlayStyle = {
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'absolute',
        zIndex: 0,
    };


    const buttonStyle = {
        backgroundColor: '#219AB3',
        color: 'white',
        borderRadius: '9999px',
        padding: '8px 20px',
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    };

    const buttonOutlineStyle = {
        ...buttonStyle,
        backgroundColor: 'transparent',
        border: '1px solid gray',
    };

    return (
        <div style={styleImg} className='fade'>
            <style>
                {`
                    @keyframes fade {
                        from { opacity: .4; }
                        to { opacity: 1; }
                    }
                    .fade {
                        animation-name: fade;
                        animation-duration: 1.5s;
                    }
                `}
            </style>
            <div style={overlayStyle} />
           
        </div>
    );
};
