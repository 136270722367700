import React, { useEffect, useState } from "react";
import api from "../../utils/api";
import "../../styles/ModifyEventForm.css"; // You can rename this to 'ModifyCategoryForm.css'

const ModifyCategoryForm = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [icon, setIcon] = useState(null); // Store the selected icon file
  const [existingIcon, setExistingIcon] = useState(null); // Store the existing icon file name
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false); // Loading state for the update request

  const userId = localStorage.getItem("userId");

  // Fetch categories when the component mounts
  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      await api.get(`/user/authenticate/${userId}`, { withCredentials: true }); // Authenticate user
      const response = await api.get("/category", { withCredentials: true });
      setCategories(response.data.result);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setErrorMessage("Failed to load categories.");
    }
  };

  // Handle category selection
  const handleSelectCategory = (category) => {
    setSuccessMessage("");
    setErrorMessage("");
    setSelectedCategory(category);
    setCategoryName(category.categoryName);
    setExistingIcon(category.icon); // Set the existing icon file name
    setIcon(null); // Clear new icon upload
  };

  // Handle icon file selection
  const handleIconChange = (e) => {
    const file = e.target.files[0]; // Get the first file
  
    if (file.type !== "image/svg+xml") {
      setErrorMessage("Icon has to be in SVG format")
      setIcon(null);
      return;
    }
    setErrorMessage(null)
    console.log(file);
    setIcon(file);
  };
  

  // Handle form submission to update category
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCategory) return;
  
    setErrorMessage("");
    setLoading(true);
  
    try {
      await api.get(`/user/authenticate/${userId}`, { withCredentials: true }); // Re-authenticate user
  
      // Update category name without using formData
      const response = await api.put(
        `/category/update-category/${userId}/${selectedCategory.id}`,
        { categoryName }, // Send only the name as JSON
        { withCredentials: true }
      );
  
      if (response.data.message === "Category updated successfully") {
        setSuccessMessage("Category name updated successfully!");
  
        // If an icon is selected, initiate icon change
        if (icon) {
          await changeCategoryIcon();
        } else {
          fetchCategories(); // Refresh category list after update
        }
  
        resetForm(); // Reset form fields
      } else {
        setErrorMessage("Failed to update category name.");
      }
    } catch (error) {
      console.error("Error updating category name:", error);
      setErrorMessage("Failed to update category name.");
    } finally {
      setLoading(false);
    }
  };
  
  const changeCategoryIcon = async () => {
    const formData = new FormData();
    formData.append("icon", icon);
  
    try {
      const response = await api.put(
        `/category/change-category-icon/${userId}/${selectedCategory.id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }
      );
  
      if (response.data.message === "Category icon changed successfully") {
        setSuccessMessage("Category icon updated successfully!");
        fetchCategories(); // Refresh category list after updating the icon
      } else {
        setErrorMessage("Failed to update category icon.");
      }
    } catch (error) {
      console.error("Error updating category icon:", error);
      setErrorMessage("Failed to update category icon.");
    }
  };
  

  // Reset the form after submission
  const resetForm = () => {
    setSelectedCategory(null);
    setCategoryName("");
    setIcon(null);
    setExistingIcon(null);
  };

  return (
    <div className="modify-event-form-container">
      <div className="modify-event-form">
        <h2 className="modify-event-form-title">Modify Category</h2>

        {!selectedCategory && (
          <select
            onChange={(e) => handleSelectCategory(JSON.parse(e.target.value))}
            className="modify-event-select"
            defaultValue=""
            value={selectedCategory}
          >
            <option value="" disabled>
              Select a category to modify
            </option>
            {categories.map((category) => (
              <option key={category.id} value={JSON.stringify(category)}>
                {category.categoryName}
              </option>
            ))}
          </select>
        )}

        {selectedCategory && (
          <form onSubmit={handleSubmit} className="modify-event-formFields">
            <input
              type="text"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              placeholder="Category Name"
              required
              className="modify-event-input"
            />

            {/* Show the icon upload section only when a category is selected */}
            <div className="modify-event-product-selection">
              <h3>Upload New Icon (SVG):</h3>
              <input
  type="file"
  onChange={handleIconChange}
  className="add-event-image-input"
  accept=".svg"
/>

            </div>

            <div className="modify-event-product-selection">
              {existingIcon && <h3>Existing Icon:</h3>}
              <div className="image-previews">
                <div className="image-preview">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}${existingIcon}`}
                    className="image-preview-thumbnail"
                    alt="Existing Icon"
                  />
                </div>
              </div>
              {icon && icon instanceof File && (
                <>
                  <h3>New Icon Preview:</h3>
                  <div className="image-previews">
                    <div className="image-preview">
                      <img
                        src={URL.createObjectURL(icon)}
                        className="image-preview-thumbnail"
                        alt="New Icon Preview"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <button
              onClick={() => setSelectedCategory(null)}
              className="modify-event-cancel-button"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="modify-event-submit-button"
              disabled={loading} // Disable the button while loading
            >
              {loading ? "Updating..." : "Update Category"} 
            </button>
          </form>
        )}
        <div style={{marginTop:"5px"}}>
         {errorMessage && <div className="error-message">{errorMessage}</div>}
         {successMessage && <div className="success-message">{successMessage}</div>}
         </div>
      </div>
    </div>
  );
};

export default ModifyCategoryForm;
